.albums-container {
  display: flex;
  width: 70%;
  position: relative;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
  align-items: flex-start;
  background-color: black;
  margin-top: 2em;

  .album-container {
    border: 5px solid white;
    margin: 10px;
    height: fit-content;
    padding: 8px;
    position: relative;
    cursor: pointer;

    img {
      width: 200px;
    }

    .album-text-container {
      position: absolute;
      text-align: center;
      width: 70%;
      height: 100%;
      top: 35%;
      padding: 0px 25px;
      display: none;

      p {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    &:hover {
      img {
        opacity: 0.4;
      }
      .album-text-container {
        display: block;
      }
    }
  }
}

body.page-photography {
  .App {
    background-image: none;
    position: sticky;

    footer {
      background: black;
      opacity: 0.9;
    }
  }
}