.photos-container {
  width: 60%;
  display: flex;
  position: relative;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
  align-items: flex-start;
  background-color: black;
  margin-top: 6em;

  .photos-back-link-wrapper{
    margin-top: -60px;
    position: absolute;
    left: 0;
    cursor: pointer;

    .text-link {
      margin-left: 20px;
      &:before {
        content: '<<  ';
        font-size: 15px
      }
    }
  }

  .photo-container {
    border: 2px solid white;
    margin: 15px;
    height: fit-content;
    padding: 8px;
    position: relative;
    cursor: pointer;
    height: 240px;
    width: 200px;

    img {
      width: 200px;
    }

    .photo-text-container {
      margin-top: -5px;
      p {
        font-size: 12px;
      }
    }
    
    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }
}
