$width-extra-small: 320px;
$width-small: 580px;
$width-medium: 800px;
$width-large: 1200px;

.home-container {
  text-align: center;
  margin-top: 15%;

  @media screen and (max-width: $width-medium){
  }

  h1 {
    font-size: 2.5em;
    @media screen and (max-width: $width-small){
      font-size: 1.5em;
    }
  }
  
  p {
    margin-top: -1em;
    font-size: 1.3em;

    @media screen and (max-width: $width-small){
      font-size: 1em;
    }
  }

  .social-links {
    margin-top: 2em;
    a {
      margin: 0 1em;
    }
    img {
      width: 30px;
    }
  }
}