$font-color: white;
$menu-border: 1px solid $font-color;
$width-extra-small: 320px;
$width-small: 580px;
$width-medium: 800px;
$width-large: 1200px;


.App {
  text-align: center;
  // background-image: url('https://live.staticflickr.com/65535/51017225923_3f1a7790a4_c.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;

  .App-header {
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    margin: auto;
    width: fit-content;
    
  
    .App-logo {
      font-weight: 100;
      img {
        width: 300px;
      }
    }

    .App-menu-list {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    
      .App-menu-item {
        padding: 10px 0;
        margin: 0 3em;
      }

      @media screen and (max-width: $width-large){
        display: none;
      }
    }
  }

  footer {
    color: #ccc;
    display: flex;
    position: fixed;
    bottom: 10px;
    font-size: 12px;
    justify-content: center;
    width: 100%;
    z-index: 3;
    height: 40px;

    div {
      margin-top: 10px;
    }

    span {
      margin: 0 10px;
    }
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 20px;
  right: 30px;
  top: 30px;
  z-index: 2 !important;
  @media screen and (min-width: $width-large){
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  text-align: left;
  a {
    padding: 0.8em;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
