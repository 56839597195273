$width-small: 580px;

.events-container {
  p {
    margin-top: -2em;
    font-size: 1.2em;
  }
}

.bit-widget-container {
  display: none;
  position: absolute;
  top: 16em;
  width: 100%;

  .bit-nav-bar-container, .bit-top-track-button {
    display: none !important;
  }
}

body.page-events {
  .bit-widget-container {
    display: block;

    .bit-widget {
      width: 70%;
      max-width: 1000px;
      margin: auto;

      @media screen and (max-width: $width-small){
        width: 85%;
      }

      .bit-play-my-city-button {
        max-width: 250px;
        margin: 2.5em auto;
      }
    }
  }

  &.bm-open {
    .bit-widget-container {
      display: none;
    }
  }
}
