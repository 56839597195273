$font-color: white;
$menu-border: 1px solid $font-color;

body {
  margin: 0;
  font-family: Comic Sans MS, Comic Sans;
  font-weight: 100;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: $font-color;

  &.page-home {
    &> div:not(.App-header) {
      height: 100%;
    }
    .home {
      border-bottom: $menu-border;
    }
    .gist {
      display: none;
    }
  }
  &.page-music {
    .music {
      border-bottom: $menu-border;
    }
    .gist {
      display: none;
    }
  }
  &.page-photography {
    .photography {
      border-bottom: $menu-border;
    }
    .gist {
      display: none;
    }
  }
  &.page-events {
    &> div:not(.App-header) {
      height: 100%;
    }
    .events {
      border-bottom: $menu-border;
    }
    .gist {
      display: none;
    }
  }
  &.page-about {
    .gist {
      display: block;
    }
  }
}

code {
  font-family: Comic Sans MS, Comic Sans,
    monospace;
}

a, a:visited, a:hover {
  color: $font-color;
  text-decoration: none;
}
