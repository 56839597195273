$width-small: 580px;

.about-container {
  display: flex;
  margin: auto;
  text-align: left;
  margin-top: 6em;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: -2em;
    font-size: 1.2em;
  }
}

.gist {
  position: absolute;
  top: 10em;
  left: 20%;
  width: 60%;

  @media screen and (max-width: $width-small){
    left: 15%;
    width: 70%;
}

  .gist-data, .gist-file {
    background-color: transparent !important;
    border-top: none !important;
    border-bottom: none !important;
    border-radius: unset !important;
    border-color: #aaa !important;
    margin: auto;
    padding: 0 0.5em;

    // :before {
    //   content: "";
    //   width: 0px;
    //   height: 0px;
    //   position: absolute;
    //   border-right: 24px solid #fff;
    //   border-left: 12px solid transparent;
    //   border-top: 12px solid #fff;
    //   border-bottom: 20px solid transparent;
    //   right: 32px;
    //   bottom: -16px;
    //   background-color: transparent;
    // }
    
  }

  .gist-data {
    border: none !important;
  }

  .gist-meta {
    display: none;
  }
  .markdown-body {
    color: white;

    * {
      border: none !important;
    }
  } 
}